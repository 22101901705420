import { Component, Vue } from 'vue-property-decorator'
import { PublicHolidayModel } from '@/models/hours-model'
import { HourService } from '@/services/hour-service'
import { ErrorService } from '@/services/error.service'
import PublicHolidayDialog from '@/components/shared/Dialogs/PublicHolidayDialog/PublicHolidayDialog.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import Commons from '@/components/shared/Helpers/commons'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Alert,
    Confirm,
    PublicHolidayDialog
  }
})
export default class PublicHolidays extends Vue {
  private hoursService = HourService.getInstance()

  public years: number[] = []
  public year = this.defaultYear
  public headers = [
    { text: 'Date', value: 'date', width: '20%' },
    { text: 'Description', value: 'description', width: '70%' },
    { text: '', value: 'actions', sortable: false, align: 'right', width: '10%' }
  ]

  public items: PublicHolidayModel[] = []
  public isLoading = false
  public errorMessages: string[] = []
  public deleteErrorMessages: string[] = []
  public selectedItem: PublicHolidayModel|null = null
  public dialog = false
  public showConfirm = false
  private isDeleting = false
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public async mounted () {
    this.errorMessages = []
    this.deleteErrorMessages = []
    this.year = this.defaultYear
    this.years = [this.year - 2, this.year - 1, this.year, this.year + 1, this.year + 2]
    this.selectedItem = null
    this.dialog = false
    this.showConfirm = false
    this.isDeleting = false
    await this.refresh(this.year)
  }

  private get defaultYear () {
    return new Date().getFullYear()
  }

  private async refresh (year) {
    this.hoursService.getPublicHolidays(year).then((result) => {
      this.items = result
      this.isLoading = false
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.isLoading = false
    })
  }

  public onYearChange (newValue) {
    this.refresh(newValue)
  }

  public showNewPublicHolidayDialog () {
    this.selectedItem = null
    this.dialog = true
  }

  public async closeDialog () {
    this.dialog = false
    this.selectedItem = null
    await this.refresh(this.year)
  }

  public editItem (item: PublicHolidayModel) {
    this.selectedItem = item
    this.dialog = true
  }

  public displayDeleteConfirmDialog (item: PublicHolidayModel) {
    this.deleteErrorMessages = []
    this.showConfirm = true
    this.selectedItem = item
  }

  public get confirmTitle () {
    if (this.selectedItem) {
      return `Supprimer ${Commons.TransformDateFormat(this.selectedItem!.date)}`
    }
    return ''
  }

  public async confirmDeleteCallback (value: boolean) {
    if (value && this.selectedItem) {
      this.isDeleting = true
      const results = await this.hoursService.deletePublicHoliday(this.selectedItem.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.deleteErrorMessages = res.errors
        }).finally(() => {
          this.isDeleting = false
        })

      if (results) {
        this.selectedItem = null
        this.showConfirm = false
        this.deleteErrorMessages = []
        this.refresh(this.year)
      }
    } else {
      this.showConfirm = false
      this.deleteErrorMessages = []
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
