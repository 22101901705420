import { AppUser } from '@/models/app-user-dto'
import { AuthService } from '@/services/auth-service'
import { ErrorService } from '@/services/error.service'
import { Component, Vue } from 'vue-property-decorator'
import { HourService } from '@/services/hour-service'
import { UserHoursConfigurationModel, UserHoursConfigurationViewModel, HistoryViewModel, ConfigurationValueModel, SoldesInitiauxEditableReponseModel } from '@/models/hours-model'
import Alert from '@/components/shared/Alert.vue'
import ConfigUserHoursDialog from '@/components/shared/Dialogs/ConfigUserHoursDialog/ConfigUserHoursDialog.vue'
import ConfigUserHoursSoldesInitiauxDialog from '@/components/shared/Dialogs/ConfigUserHoursSoldesInitiauxDialog/ConfigUserHoursSoldesInitiauxDialog.vue'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Alert,
    ConfigUserHoursDialog,
    ConfigUserHoursSoldesInitiauxDialog
  }
})
export default class ConfigHours extends Vue {
  private authService = AuthService.getInstance()
  private hourService = HourService.getInstance()

  public allUsers: AppUser[] = []
  public userConfig: UserHoursConfigurationModel[] = []
  public data: any[] = []

  public headers: any[] = []
  public isLoading = false
  public errorMessages: string[] = []
  public showDialog = false
  public showSoldesInitiaux = false
  public selectedItem: UserHoursConfigurationViewModel|null = null
  public soldeInitiauxEditable: SoldesInitiauxEditableReponseModel[] = []
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public async mounted () {
    this.isLoading = true
    await this.getAllUsers()
    this.headers = [
      {
        text: 'Utilisateur',
        value: 'userName'
      },
      { text: 'Pourcentage', value: 'percent' },
      { text: 'Heures/jour (100%)', value: 'hoursDay' },
      { text: 'Vacances', value: 'holidays' },
      { text: 'Solde initiaux', value: 'hoursInitial' },
      { text: '', value: 'actions', sortable: false, align: 'right' }
    ]
    this.getHoursConfig().finally(() => {
      this.isLoading = false
    })
  }

  public async getAllUsers () {
    await this.authService.getAllUsers().then((users) => {
      this.allUsers = users
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    })
  }

  public async getHoursConfig () {
    this.isLoading = true
    await this.hourService.areInitialBalanceEditable().then(response => {
      this.soldeInitiauxEditable = response
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    })

    await this.hourService.getUserHourConfig().then((config) => {
      this.userConfig = config
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    })

    this.data = this.userConfig.map(config => {
      const percentSorted = config.pourcentsHistory ?? []
      const hoursSorted = config.heuresJoursHistory ?? []
      const holidaysSorted = config.vacancesHistory ?? []
      const c = new UserHoursConfigurationViewModel(config.userId, percentSorted.map(hs => {
        const h: HistoryViewModel = {
          date: new Date(hs.debut),
          value: `${hs.value}%`
        }
        return h
      }), hoursSorted.map(hs => {
        const h: HistoryViewModel = {
          date: new Date(hs.debut),
          value: `${hs.value}h/j`
        }
        return h
      }), holidaysSorted.map(hs => {
        const h: HistoryViewModel = {
          date: new Date(hs.debut),
          value: `${hs.value}j/a`
        }
        return h
      }), this.getUserName(config.userId), config.pourcent, config.heuresJour, config.vacances, config.suggestedMonth, config.soldeInitial)
      return c
    })
  }

  public getUserName (userId: string) {
    return this.allUsers.find(u => u.id.toLowerCase() === userId.toLowerCase())?.fullName
  }

  public editAbsences (item: UserHoursConfigurationViewModel) {
    const path = `/administration/absences/${item.userId}`
    this.$router.push(path)
  }

  public canEditHours () {
    return this.authService.Roles.includes('UserManagement')
  }

  public editConfig (item: UserHoursConfigurationViewModel) {
    this.selectedItem = item
    this.showDialog = true
  }

  public closeDialog () {
    this.showDialog = false
    this.selectedItem = null
    this.getHoursConfig().finally(() => {
      this.isLoading = false
    })
  }

  public showSoldesInitiauxDialog (item: UserHoursConfigurationViewModel) {
    this.selectedItem = item
    this.showSoldesInitiaux = true
  }

  public closeSoldesInitiauxDialog () {
    this.showSoldesInitiaux = false
    this.selectedItem = null
    this.getHoursConfig().finally(() => {
      this.isLoading = false
    })
  }

  public hasFirstEntry (item: UserHoursConfigurationViewModel) {
    return item.hasFirstEntry()
  }

  public canEditSoldesInitiaux (item: UserHoursConfigurationViewModel) {
    const r = this.soldeInitiauxEditable.find(s => s.userId.toLowerCase() === item.userId.toLowerCase())
    if (r) {
      return r.isEditable
    }
    return true
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
