var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[(!!_vm.errorMessages.length)?_c('div',[_c('alert',{attrs:{"value":!!_vm.errorMessages.length,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)])],1):_vm._e(),_c('v-row',[_c('c-col',[_c('select-input',{ref:"AnnéeRef",attrs:{"items":_vm.years,"label":"Année","name":"Année","clearable":false},on:{"change":_vm.onYearChange},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1)],1),_c('c-btn',{staticClass:"btn-red",on:{"click":function($event){return _vm.showNewPublicHolidayDialog()}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Ajouter un nouveau jour férié")])],1),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"date","loading":_vm.isLoading,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isLoading }},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('limited-char',{attrs:{"text":item.description,"limit":100}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-fr")(item.date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)]}}],null,true)}),_c('public-holiday-dialog',{attrs:{"visible":_vm.dialog,"year":_vm.year,"item":_vm.selectedItem},on:{"close":function($event){return _vm.closeDialog()}}}),(_vm.selectedItem !== null)?_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":_vm.confirmTitle,"errorMessages":_vm.deleteErrorMessages,"message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer ce jour férié ?","yesLoading":_vm.isDeleting},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }