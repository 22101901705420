import { IDateRange, INestedTableObject } from "./common-models"
import { dossierPatientModel } from "./dossier-response-model"

export interface PublicHolidayModel {
  id?: number;
  date?: string;
  description?: string;
}

export interface HistoryViewModel {
  date: Date;
  value: string;
}

export class UserHoursConfigurationViewModel {
  userId: string;
  userName?: string;
  percent?: ConfigurationValueModel;
  percentHistory: HistoryViewModel[];
  hoursDay?: ConfigurationValueModel;
  hoursDayHistory: HistoryViewModel[];
  holidays?: ConfigurationValueModel;
  holidaysHistory: HistoryViewModel[];
  soldeInitial?: HeureMoisSoldeModel;
  suggestedMonth?: string;

  constructor (userId: string, percentHistory: HistoryViewModel[], hoursDayHistory: HistoryViewModel[], holidaysHistory: HistoryViewModel[], userName? : string, currentPercent?: ConfigurationValueModel, currentHoursDay?: ConfigurationValueModel, currentHolidays?: ConfigurationValueModel, suggestedMonth?: string, soldeInitial?: HeureMoisSoldeModel) {
    this.userId = userId
    this.userName = userName
    this.percent = currentPercent
    this.hoursDay = currentHoursDay
    this.holidays = currentHolidays
    this.percentHistory = percentHistory
    this.hoursDayHistory = hoursDayHistory
    this.holidaysHistory = holidaysHistory
    this.suggestedMonth = suggestedMonth
    this.soldeInitial = soldeInitial
  }

  public hasFirstEntry () {
    return this.holidaysHistory.length > 0 && this.hoursDayHistory.length > 0 && this.percentHistory.length > 0
  }
}

export interface UserHoursConfigurationModel {
  userId: string;
  heuresJour?: ConfigurationValueModel;
  pourcent?: ConfigurationValueModel;
  vacances?: ConfigurationValueModel;
  heuresJoursHistory: ConfigurationValueModel[];
  pourcentsHistory: ConfigurationValueModel[];
  vacancesHistory: ConfigurationValueModel[];
  soldeInitial: HeureMoisSoldeModel;
  suggestedMonth?: string;
}

export interface ConfigurationValueModel {
  id: number;
  value: number;
  debut: string;
}

export interface UserHourConfigurationValues {
  percent?: number;
  percentStart?: string;
  hours?: number;
  hoursStart?: string;
  holidays?: number;
  holidaysStart?: string;
}

export interface UserHoursSoldeInitiauxValues {
  id?: number;
  dateDebut?: string;
  soldeHeuresInitial?: number;
  soldeHeuresInitialStr?: string;
  soldeVacancesInitial?: number;
}

export interface HeureMoisSoldeModel {
  id: number;
  date: string;
  soldeHeures: number;
  soldeHeuresStr: string;
  soldeVacances: number;
}

export interface SoldesInitiauxEditableReponseModel {
  userId: string;
  isEditable: boolean;
}

export interface HeuresMoisAnneeModel {
  id: number;
  annee: number;
  mois1: number;
  mois2: number;
  mois3: number;
  mois4: number;
  mois5: number;
  mois6: number;
  mois7: number;
  mois8: number;
  mois9: number;
  mois10: number;
  mois11: number;
  mois12: number;
}

export interface HeuresMoisAnneeEditableReponseModel {
  year: number;
  isEditable: boolean;
}

export interface HeuresResponseModel {
  isValidated: boolean;
  hoursToDo: number;
  pourcentage: number;
  hoursPerDay: number;
  totalHoursDoneForMonth: number;
  totalHoursDoneForMonthStr: string;
  oldHoursBalance?: number;
  oldHoursBalanceStr?: string;
  newHoursBalance?: number;
  newHoursBalanceStr?: string;
  secondsToWorkForMonth?: number;
  hoursToWorkForMonthStr?: string;
  holidaysAllowancePerYear: number;
  holidaysLeftToPlanForYear: number;
  hoursBalanceDate?: string;

  holidaySummaryDate: string;
  holidaysTaken: number;
  holidaysPlanned: number;

  hasHolidayBalanceChange: boolean;

  activities: HeuresActivityResponseModel[];
}

export interface HeuresActivityResponseModel {
  id: number;
  categoryId: number;
  numbering: string;
  description: string;
  canBeManual: boolean;
  heures: HeuresEntryResponseModel[];
}

export interface HeuresEntryResponseModel {
  id: number;
  isAutomatic: boolean;
  dossierId?: string;
  hours?: number;
  minutes?: number;
  isFullDay?: boolean;
  isHalfDay?: boolean;
  patientCode?: number;
  patientFullName?: string;
}

export interface HeuresReqModel extends HeuresResponseModel {
  date: string;
}

export interface HeuresCategoryModel {
  title: string;
  numbering: string;
  color: string;
  hasPatient: boolean;
  total: string;
  id: number;
  hasDaysAndHalfDays: boolean;
}

export interface HoursSearchModel {
  patients?: dossierPatientModel[];
  userIds?: string[];
  activityIds?: number[];
  searchPeriod?: IDateRange;
  page: number;
  pageSize: number;
}

export interface HoursSearchRequest {
  patientIds?: string[];
  userIds?: string[];
  activityIds?: number[];
  searchPeriod?: IDateRange;
  page: number;
  pageSize: number;
}

export interface HourCategory {
  id: number;
  description: string;
  activities: HourActivity[];
}

export interface HourActivity {
  id: number;
  categoryId: number;
  description: string;
}

export interface HoursResponseModel extends INestedTableObject {
  userId: string;
  totalLength: number;
  data: UserMonthHoursResponseModel[];
}

export interface UserMonthHoursResponseModel {
  heureMoisId?: number;
  validationDate?: string;
  validator?: string;
  date: string;
  secondsBalanceEndOfPreviousMonth: number;
  hourBalanceEndOfPreviousMonthStr: string;
  secondsDoneInMonth: number;
  hoursDoneInMonthStr: string;
  secondsToDoInMonth: number;
  hoursToDoInMonthStr: string;
  holidayBalanceEndOfPreviousMonth: number;
  validationStatus: number;
  cancellable: boolean;
  balanceEditable: boolean;
  lastHourBalanceChange?: number;
  lastHourBalanceChangeStr?: string;
  lastHourBalanceChangeOldValue?: number;
  lastHourBalanceChangeOldValueStr?: string;
  lastHourBalanceChangeComment?: string;
  lastHolidayBalanceChange?: number;
  lastHolidayBalanceChangeOldValue?: number;
  lastHolidayBalanceChangeComment?: string;
  days: UserDayHoursResponseModel[];
}

export interface UserDayHoursResponseModel {
  day: string;
  secondsDone: number;
  hoursDoneStr: string;
  secondsToDo: number;
  hoursToDoStr: string;
  secondsBalanceEndOfDay: number;
  hoursBalanceEndOfDayStr: string;
  holidayBalanceEndOfDay: number;
  isDayOff: boolean;
}
