var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[(!!_vm.errorMessages.length)?_c('div',[_c('alert',{attrs:{"value":!!_vm.errorMessages.length,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)])],1):_vm._e(),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"annee","sort-desc":true,"loading":_vm.isLoading,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isLoading }},scopedSlots:_vm._u([_vm._l(([1,2,3,4,5,6,7,8,9,10,11,12]),function(i){return {key:("item.mois" + i),fn:function(ref){
var item = ref.item;
return [(item.id > 0)?_c('span',{key:("month-" + (item.annee) + "-" + i)},[_vm._v(" "+_vm._s(item[("mois" + i)])+" ")]):_c('span',{key:("month-" + (item.annee) + "-" + i)},[_vm._v("-")])]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.id <= 0 || _vm.canEdit(item))?_c('div',{staticClass:"table-row-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){$event.stopPropagation();return _vm.editYear(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),(item.id > 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){$event.stopPropagation();return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()],1):_vm._e()]}}],null,true)}),_c('work-days-dialog',{attrs:{"visible":_vm.dialog,"item":_vm.selectedItem},on:{"close":function($event){return _vm.closeDialog()}}}),(_vm.selectedItem !== null)?_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":_vm.confirmTitle,"errorMessages":_vm.deleteErrorMessages,"message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer cette entrée ?","yesLoading":_vm.isDeleting},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }