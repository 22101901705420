import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import Commons from '../../Helpers/commons'
import { UserHourConfigurationValues, UserHoursConfigurationViewModel } from '@/models/hours-model'
import { HourService } from '@/services/hour-service'
import { ErrorService } from '@/services/error.service'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Alert
  }
})
export default class ConfigUserHoursDialog extends Vue {
  private hourService = HourService.getInstance()

  @Prop()
  public visible!: boolean

  @Prop({ default: undefined })
  public item!: UserHoursConfigurationViewModel

  public errorMessages: string[] = []

  public isSaving = false

  public model: UserHourConfigurationValues = {}

  public holidayStartDateMenu = false
  public hoursStartDateMenu = false
  public percentStartDateMenu = false
  public holidayStartDateFormatted = ''
  public hoursStartDateFormatted = ''
  public percentStartDateFormatted = ''

  public getDatesFormatted () {
    if (this.model.holidaysStart) {
      this.holidayStartDateFormatted = Commons.TransformDateFormatMonthYear(new Date(this.model.holidaysStart))!
    } else {
      this.holidayStartDateFormatted = ''
    }
    if (this.model.hoursStart) {
      this.hoursStartDateFormatted = Commons.TransformDateFormatMonthYear(new Date(this.model.hoursStart))!
    } else {
      this.hoursStartDateFormatted = ''
    }
    if (this.model.percentStart) {
      this.percentStartDateFormatted = Commons.TransformDateFormatMonthYear(new Date(this.model.percentStart!))!
    } else {
      this.percentStartDateFormatted = ''
    }
  }

  get show () {
    return this.visible
  }

  @Watch('model.percentStart')
  public onPercentStartChanged () {
    if (this.item && !this.item.hasFirstEntry()) {
      this.model.holidaysStart = this.model.hoursStart = this.model.percentStart
      this.getDatesFormatted()
    }
  }

  @Watch('visible')
  public visibleChange () {
    if (!this.visible) {
      this.resetDialog()
    } else {
      if (this.item.percent) {
        this.$set(this.model, 'percent', this.item.percent.value)
        this.$set(this.model, 'percentStart', Commons.FormatDateForInputField(new Date(this.item.percent.debut)))
      } else {
        this.$set(this.model, 'percent', undefined)
        this.$set(this.model, 'percentStart', undefined)
      }
      if (this.item.hoursDay) {
        this.$set(this.model, 'hours', this.item.hoursDay.value)
        this.$set(this.model, 'hoursStart', Commons.FormatDateForInputField(new Date(this.item.hoursDay.debut)))
      } else {
        this.$set(this.model, 'hours', undefined)
        this.$set(this.model, 'hoursStart', undefined)
      }
      if (this.item.holidays) {
        this.$set(this.model, 'holidays', this.item.holidays.value)
        this.$set(this.model, 'holidaysStart', Commons.FormatDateForInputField(new Date(this.item.holidays.debut)))
      } else {
        this.$set(this.model, 'holidays', undefined)
        this.$set(this.model, 'holidaysStart', undefined)
      }
      this.getDatesFormatted()
    }
  }

  public async save () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      this.hourService.addUserHourConfig(this.item.userId, this.model).then(r => {
        this.emitClose()
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isSaving = false
      })
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public emitClose () {
    this.$emit('close')
  }

  private resetDialog () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    observer.reset()
    this.isSaving = false
    this.model = {}
    this.hideAlert()
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
