import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import Commons from '../../Helpers/commons'
import { SoldesInitiauxEditableReponseModel, UserHoursConfigurationViewModel, UserHoursSoldeInitiauxValues } from '@/models/hours-model'
import { HourService } from '@/services/hour-service'
import { ErrorService } from '@/services/error.service'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Alert
  }
})
export default class ConfigUserHoursSoldesInitiauxDialog extends Vue {
  private hourService = HourService.getInstance()

  @Prop()
  public visible!: boolean

  @Prop({ default: undefined })
  public item!: UserHoursConfigurationViewModel

  @Prop({ type: Array, required: false, default: () => { return [] } })
  public soldeInitiauxEditable!: SoldesInitiauxEditableReponseModel[]

  public errorMessages: string[] = []

  public isSaving = false

  public model: UserHoursSoldeInitiauxValues = {}

  public dateDebutMenu = false

  public dateFormatted = ''

  get show () {
    return this.visible
  }

  public getDateFormatted () {
    if (this.model.dateDebut) {
      this.dateFormatted = Commons.TransformDateFormatMonthYear(new Date(this.model.dateDebut)) ?? Commons.TransformDateFormatMonthYear(this.suggestedMonth) ?? ''
    } else {
      this.dateFormatted = Commons.TransformDateFormatMonthYear(this.suggestedMonth) ?? ''
    }
  }

  @Watch('visible')
  public visibleChange () {
    if (!this.visible) {
      this.resetDialog()
    } else {
      if (this.item?.soldeInitial) {
        this.$set(this.model, 'id', this.item.soldeInitial.id)
        this.$set(this.model, 'dateDebut', Commons.FormatDateForInputField(new Date(this.item.soldeInitial.date)))
        this.$set(this.model, 'soldeHeuresInitial', this.item.soldeInitial.soldeHeures)
        this.$set(this.model, 'soldeHeuresInitialStr', this.item.soldeInitial.soldeHeuresStr)
        this.$set(this.model, 'soldeVacancesInitial', this.item.soldeInitial.soldeVacances)
      } else {
        this.$set(this.model, 'dateDebut', Commons.FormatDateForInputField(this.suggestedMonth))
      }
      this.getDateFormatted()
    }
  }

  public async save () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      this.hourService.updateSoldesInitiaux(this.item.userId, this.model).then(r => {
        this.emitClose()
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isSaving = false
      })
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public emitClose () {
    this.$emit('close')
  }

  private resetDialog () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    observer.reset()
    this.isSaving = false
    this.model = {}
    this.hideAlert()
  }

  public canEdit () {
    const r = this.soldeInitiauxEditable.find(u => u.userId.toLowerCase() === this.item.userId.toLowerCase())
    if (r) {
      return r.isEditable
    }
    return true
  }

  public get suggestedMonth () {
    return new Date(this.item.suggestedMonth!)
  }

  public get transformedSuggestedMonth () {
    return Commons.FormatDateForInputField(this.suggestedMonth)
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public timeMask (value) {
    return Commons.timeMask(value)
  }
}
