var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item && _vm.visible)?_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("Modifier la configuration des heures de travail de "+_vm._s(_vm.item.userName))])]),(!!_vm.errorMessages.length)?_c('v-card-text',[_c('alert',{attrs:{"value":!!_vm.errorMessages.length,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)])],1):_vm._e(),_c('v-card-text',[_c('validation-observer',{ref:"dialogObserver"},[_c('form',[_c('div',{staticClass:"subsection"},[_c('v-row',[_c('c-col',{attrs:{"md":6}},[_c('text-input',{ref:"PourcentageRef",attrs:{"label":"Pourcentage","name":"Pourcentage","type":"number","rules":"required|positive","min":"0"},model:{value:(_vm.model.percent),callback:function ($$v) {_vm.$set(_vm.model, "percent", $$v)},expression:"model.percent"}})],1),_c('c-col',{attrs:{"md":6}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('text-input',{ref:"Date de début du pourcentageRef",attrs:{"label":"Date de début","name":"Date de début du pourcentage","rules":"required","readonly":"","customBind":attrs,"customOn":on},model:{value:(_vm.percentStartDateFormatted),callback:function ($$v) {_vm.percentStartDateFormatted=$$v},expression:"percentStartDateFormatted"}})]}}],null,false,1412281016),model:{value:(_vm.percentStartDateMenu),callback:function ($$v) {_vm.percentStartDateMenu=$$v},expression:"percentStartDateMenu"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"input":function($event){_vm.percentStartDateMenu = false},"change":function($event){return _vm.getDatesFormatted()}},model:{value:(_vm.model.percentStart),callback:function ($$v) {_vm.$set(_vm.model, "percentStart", $$v)},expression:"model.percentStart"}})],1)],1),_c('c-col',{attrs:{"md":6}},[_c('text-input',{ref:"HeuresRef",attrs:{"label":"Heures/Jour (100%)","name":"Heures","type":"number","rules":"required|positive","min":"0"},model:{value:(_vm.model.hours),callback:function ($$v) {_vm.$set(_vm.model, "hours", $$v)},expression:"model.hours"}})],1),_c('c-col',{attrs:{"md":6}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('text-input',{ref:"DateHeuresRef",attrs:{"label":"Date de début","name":"Date de début des heures/jour","vid":"DateHeures","rules":"required","readonly":"","customBind":attrs,"customOn":on,"disabled":!_vm.item.hasFirstEntry()},model:{value:(_vm.hoursStartDateFormatted),callback:function ($$v) {_vm.hoursStartDateFormatted=$$v},expression:"hoursStartDateFormatted"}})]}}],null,false,3712576684),model:{value:(_vm.hoursStartDateMenu),callback:function ($$v) {_vm.hoursStartDateMenu=$$v},expression:"hoursStartDateMenu"}},[_c('v-date-picker',{attrs:{"type":"month","disabled":!_vm.item.hasFirstEntry()},on:{"input":function($event){_vm.hoursStartDateMenu = false},"change":function($event){return _vm.getDatesFormatted()}},model:{value:(_vm.model.hoursStart),callback:function ($$v) {_vm.$set(_vm.model, "hoursStart", $$v)},expression:"model.hoursStart"}})],1)],1),_c('c-col',{attrs:{"md":6}},[_c('text-input',{ref:"VacancesRef",attrs:{"label":"Vacances (100%)","name":"Vacances","type":"number","rules":"required|positive","min":"0"},model:{value:(_vm.model.holidays),callback:function ($$v) {_vm.$set(_vm.model, "holidays", $$v)},expression:"model.holidays"}})],1),_c('c-col',{attrs:{"md":6}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('text-input',{ref:"DateVacancesRef",attrs:{"label":"Date de début","name":"Date de début des vacances","vid":"DateVacances","rules":"required","readonly":"","customBind":attrs,"customOn":on,"disabled":!_vm.item.hasFirstEntry()},model:{value:(_vm.holidayStartDateFormatted),callback:function ($$v) {_vm.holidayStartDateFormatted=$$v},expression:"holidayStartDateFormatted"}})]}}],null,false,4144499934),model:{value:(_vm.holidayStartDateMenu),callback:function ($$v) {_vm.holidayStartDateMenu=$$v},expression:"holidayStartDateMenu"}},[_c('v-date-picker',{attrs:{"type":"month","disabled":!_vm.item.hasFirstEntry()},on:{"input":function($event){_vm.holidayStartDateMenu = false},"change":function($event){return _vm.getDatesFormatted()}},model:{value:(_vm.model.holidaysStart),callback:function ($$v) {_vm.$set(_vm.model, "holidaysStart", $$v)},expression:"model.holidaysStart"}})],1)],1)],1)],1)])])],1),_c('v-card-actions',[_c('c-btn',{staticClass:"btn-white",on:{"click":function($event){return _vm.emitClose()}}},[_vm._v(" Annuler ")]),_c('c-btn',{staticClass:"btn-red",attrs:{"loading":_vm.isSaving},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.item.id ? 'Mise à jour' : 'Enregistrer')+" ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }