import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { HeuresMoisAnneeModel } from '@/models/hours-model'
import { HourService } from '@/services/hour-service'
import { ErrorService } from '@/services/error.service'
import Commons from '../../Helpers/commons'
import Alert from '@/components/shared/Alert.vue'

@Component({
  components: {
    Alert,
    ValidationObserver
  }
})
export default class WorkDaysDialog extends Vue {
  private hourService = HourService.getInstance()

  @Prop()
  public visible!: boolean

  @Prop()
  public item!: HeuresMoisAnneeModel

  public isSaving = false
  public errorMessages: string[] = []

  public months = [
    {
      name: 'Jan',
      id: 'mois1'
    },
    {
      name: 'Fév',
      id: 'mois2'
    },
    {
      name: 'Mar',
      id: 'mois3'
    },
    {
      name: 'Avr',
      id: 'mois4'
    },
    {
      name: 'Mai',
      id: 'mois5'
    },
    {
      name: 'Jun',
      id: 'mois6'
    },
    {
      name: 'Jul',
      id: 'mois7'
    },
    {
      name: 'Aoû',
      id: 'mois8'
    },
    {
      name: 'Sep',
      id: 'mois9'
    },
    {
      name: 'Oct',
      id: 'mois10'
    },
    {
      name: 'Nov',
      id: 'mois11'
    },
    {
      name: 'Déc',
      id: 'mois12'
    }
  ]

  get show () {
    return this.visible
  }

  public emitClose () {
    this.$emit('close')
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      this.hourService.addHoursPerMonthPerYear(this.item).then(r => {
        this.emitClose()
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isSaving = false
      })
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
