import { LayoutService } from '@/services/layout-service'
import { Component, Vue } from 'vue-property-decorator'
import ConfigHours from '@/views/Administration/Hours/ConfigHours/ConfigHours.vue'
import PublicHolidays from '@/views/Administration/Hours/PublicHolidays/PublicHolidays.vue'
import WorkDays from '@/views/Administration/Hours/WorkDays/WorkDays.vue'
import { AdministrationHelper } from '../AdministrationHelper'

@Component({
  components: {
    ConfigHours,
    PublicHolidays,
    WorkDays
  }
})

export default class Hours extends Vue {
  private layoutService = LayoutService.getInstance()
  public tab = null
  public tabs = ['Heures de travail', 'Jours fériés', 'Jours de travail']

  public mounted () {
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
  }
}
