var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[(!!_vm.errorMessages.length)?_c('div',[_c('alert',{attrs:{"value":!!_vm.errorMessages.length,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)])],1):_vm._e(),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"sort-by":['fullName'],"loading":_vm.isLoading,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isLoading }},scopedSlots:_vm._u([_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); })),function(header){return {key:("item." + (header.value)),fn:function(ref){
var header = ref.header;
var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primary--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.percent ? item.percent.value : '-')+" ")])]}}],null,true)},[_c('div',{staticClass:"history-tooltip"},[_c('h5',[_vm._v("Historique")]),_c('ul',_vm._l((item.percentHistory),function(hist,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(_vm._f("date-fr")(hist.date))+" : "+_vm._s(hist.value)+" ")])}),0)])])]}},{key:"item.hoursDay",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primary--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.hoursDay ? item.hoursDay.value : '-')+" ")])]}}],null,true)},[_c('div',{staticClass:"history-tooltip"},[_c('h5',[_vm._v("Historique")]),_c('ul',_vm._l((item.hoursDayHistory),function(hist,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(_vm._f("date-fr")(hist.date))+" : "+_vm._s(hist.value)+" ")])}),0)])])]}},{key:"item.holidays",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primary--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.holidays ? item.holidays.value : '-')+" ")])]}}],null,true)},[_c('div',{staticClass:"history-tooltip"},[_c('h5',[_vm._v("Historique")]),_c('ul',_vm._l((item.holidaysHistory),function(hist,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(_vm._f("date-fr")(hist.date))+" : "+_vm._s(hist.value)+" ")])}),0)])])]}},{key:"item.hoursInitial",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-value"},[_vm._v(" "+_vm._s(item.hoursInitial)+" ")]),(_vm.hasFirstEntry(item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canEditSoldesInitiaux(item))?_c('v-icon',_vm._g(_vm._b({class:item.soldeInitial ? '' : 'action-edit',on:{"click":function($event){return _vm.showSoldesInitiauxDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.soldeInitial ? "mdi-calendar-edit" : "mdi-calendar-cursor")+" ")]):_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.showSoldesInitiauxDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-circle-outline ")])]}}],null,true)},[_c('div',[(item.soldeInitial)?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.canEditSoldesInitiaux(item) ? "Modifier les soldes initiaux" : "Visualiser les soldes initiaux"))]),_c('br'),_vm._v(" Début: "+_vm._s(_vm._f("date-fr")(item.soldeInitial.date))),_c('br'),_c('strong',[_vm._v(_vm._s(item.soldeInitial.soldeHeuresStr))]),_vm._v(" heures supplémentaires"),_c('br'),_c('strong',[_vm._v(_vm._s(item.soldeInitial.soldeVacances))]),_vm._v(" jours de vacances ")]):_c('div',[_vm._v("Définir les soldes initiaux")])])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[(_vm.canEditHours())?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-time",on:{"click":function($event){return _vm.editAbsences(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-clipboard-clock-outline ")])]}}],null,true)},[_c('span',[_vm._v("Absences planifiées")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editConfig(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])])],1)]}}],null,true)}),_c('config-user-hours-dialog',{attrs:{"visible":_vm.showDialog,"item":_vm.selectedItem},on:{"close":function($event){return _vm.closeDialog()}}}),_c('config-user-hours-soldes-initiaux-dialog',{attrs:{"visible":_vm.showSoldesInitiaux,"item":_vm.selectedItem,"soldeInitiauxEditable":_vm.soldeInitiauxEditable},on:{"close":function($event){return _vm.closeSoldesInitiauxDialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }