import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { ValidationObserver } from 'vee-validate'
import { HourService } from '@/services/hour-service'
import { PublicHolidayModel } from '@/models/hours-model'
import Commons from '../../Helpers/commons'

@Component({
  components: {
    Alert,
    ValidationObserver
  }
})
export default class PublicHolidayDialog extends Vue {
  private hourService = HourService.getInstance()

  @Prop()
  public visible!: boolean

  @Prop({ default: 0 })
  public year!: number

  @Prop({ default: null })
  public item!: PublicHolidayModel

  public isSaving = false
  private errorMessages: string[] = []
  public date = ''
  public description = ''

  @Watch('visible') onVisibleChange (v) {
    if (v) {
      this.resetDialog()
    }
  }

  public mounted () {
    this.resetDialog()
  }

  private resetDialog () {
    this.errorMessages = []
    this.isSaving = false
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    if (observer) {
      observer.reset()
    }
    if (this.item) {
      this.date = this.item.date!
      this.description = this.item.description!
    } else {
      this.date = this.description = ''
    }
  }

  get show () {
    return this.visible
  }

  public close () {
    this.$emit('close')
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      const data: PublicHolidayModel = {
        id: this.item?.id ?? 0,
        date: this.date,
        description: this.description
      }

      const response = await this.hourService.addUpdatePublicHoliday(data)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if (response) {
        this.close()
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
