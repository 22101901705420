import { Component, Vue, Watch } from 'vue-property-decorator'
import WorkDaysDialog from '@/components/shared/Dialogs/WorkDaysDialog/WorkDaysDialog.vue'
import { HourService } from '@/services/hour-service'
import { ErrorService } from '@/services/error.service'
import { HeuresMoisAnneeEditableReponseModel, HeuresMoisAnneeModel } from '@/models/hours-model'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Alert,
    Confirm,
    WorkDaysDialog
  }
})
export default class WorkDays extends Vue {
  private hourService = HourService.getInstance()

  public dialog = false
  public isLoading = false
  public errorMessages: string[] = []
  public deleteErrorMessages: string[] = []
  public items: HeuresMoisAnneeModel[] = []
  public itemsEditable: HeuresMoisAnneeEditableReponseModel[] = []
  public selectedItem: HeuresMoisAnneeModel|null = null
  public showConfirm = false
  private isDeleting = false
  private isLoadingHma = false
  private isLoadingEditable = false
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public headers = [
    {
      text: 'Année',
      value: 'annee'
    },
    {
      text: 'Jan',
      value: 'mois1',
      sortable: false
    },
    {
      text: 'Fév',
      value: 'mois2',
      sortable: false
    },
    {
      text: 'Mar',
      value: 'mois3',
      sortable: false
    },
    {
      text: 'Avr',
      value: 'mois4',
      sortable: false
    },
    {
      text: 'Mai',
      value: 'mois5',
      sortable: false
    },
    {
      text: 'Jun',
      value: 'mois6',
      sortable: false
    },
    {
      text: 'Jul',
      value: 'mois7',
      sortable: false
    },
    {
      text: 'Aoû',
      value: 'mois8',
      sortable: false
    },
    {
      text: 'Sep',
      value: 'mois9',
      sortable: false
    },
    {
      text: 'Oct',
      value: 'mois10',
      sortable: false
    },
    {
      text: 'Nov',
      value: 'mois11',
      sortable: false
    },
    {
      text: 'Déc',
      value: 'mois12',
      sortable: false
    },
    {
      text: '',
      value: 'actions',
      sortable: false
    }
  ]

  private updateLoadingState () {
    this.isLoading = this.isLoadingHma || this.isLoadingEditable
  }

  @Watch('isLoadingHma')
  public isLoadingHmaChanged () {
    this.updateLoadingState()
  }

  @Watch('isLoadingEditable')
  public isLoadingEditableChanged () {
    this.updateLoadingState()
  }

  public async mounted () {
    this.selectedItem = null
    this.dialog = false
    this.showConfirm = false
    this.isDeleting = false
    await this.refresh()
  }

  private generateEmptyItem (year: number) {
    return {
      annee: year,
      id: 0,
      mois1: 0,
      mois2: 0,
      mois3: 0,
      mois4: 0,
      mois5: 0,
      mois6: 0,
      mois7: 0,
      mois8: 0,
      mois9: 0,
      mois10: 0,
      mois11: 0,
      mois12: 0
    }
  }

  public async refresh () {
    this.isLoadingEditable = true
    this.isLoadingHma = true
    this.hourService.getHoursPerMonthPerYear().then(result => {
      this.items = result
      if (this.items.length <= 0) {
        const today = new Date()
        this.items.push(this.generateEmptyItem(today.getFullYear()))
      } else {
        const maxYear = this.items.reduce((prev, curr) => {
          return prev.annee > curr.annee ? prev : curr
        })
        this.items.push(this.generateEmptyItem(maxYear.annee + 1))
      }
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.isLoadingHma = false
    })

    this.hourService.areHoursPerMonthPerYearEditable().then(result => {
      this.itemsEditable = result
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.isLoadingEditable = false
    })
  }

  public canEdit (item: HeuresMoisAnneeModel) {
    const f = this.itemsEditable.find(r => r.year === item.annee)
    if (f) {
      return f.isEditable
    }
    return true
  }

  public editYear (item: HeuresMoisAnneeModel) {
    this.selectedItem = item
    this.dialog = true
  }

  public async closeDialog () {
    this.dialog = false
    this.selectedItem = null
    await this.refresh()
  }

  public displayDeleteConfirmDialog (item: HeuresMoisAnneeModel) {
    this.deleteErrorMessages = []
    this.showConfirm = true
    this.selectedItem = item
  }

  public get confirmTitle () {
    if (this.selectedItem) {
      return `Supprimer les jours à travailler par mois pour l'année ${this.selectedItem.annee}`
    }
    return ''
  }

  public async confirmDeleteCallback (value: boolean) {
    if (value && this.selectedItem) {
      this.isDeleting = true
      const results = await this.hourService.deleteHoursPerMonthPerYear(this.selectedItem.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.deleteErrorMessages = res.errors
        }).finally(() => {
          this.isDeleting = false
        })

      if (results) {
        this.selectedItem = null
        this.showConfirm = false
        this.deleteErrorMessages = []
        this.refresh()
      }
    } else {
      this.showConfirm = false
      this.deleteErrorMessages = []
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
